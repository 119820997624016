import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column",
  "data-kt-landing": "true"
}
const _hoisted_2 = { class: "d-flex flex-row" }
const _hoisted_3 = { class: "fw-normal landingTitle flex-fill" }
const _hoisted_4 = { class: "landingSubtitle pb-5" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "mr-2 pt-5 textoCentrado" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "mr-2 pt-5 textoCentrado" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FamiliaInfo = _resolveComponent("FamiliaInfo")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.i18n("planCorresponsables")), 1),
        _createVNode(_component_FamiliaInfo)
      ]),
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.i18n("formularioInscripcion")), 1),
      _createElementVNode("div", {
        class: "textoJustificado",
        innerHTML: _ctx.$t('descripcionFormularioFamilias')
      }, null, 8, _hoisted_5)
    ]),
    (!_ctx.token)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_router_link, {
              to: "/formulario-familias-paso-inicial",
              class: "btn btn-primary botonForm",
              "active-class": "active"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.i18n("cumplimentarCuestionarioBtn")), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.token)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_router_link, {
              to: _ctx.startStepUrl,
              class: "btn btn-primary botonForm",
              "active-class": "active"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.i18n("cumplimentarCuestionarioBtn")), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}